// Font family
$font-stack: 'Noto Sans', sans-serif;
$font-fantaisy: 'Caveat', cursive; 

html {
  color: $bk-a;
  font-size: #{$font-size}px !important;
}

body {
  font-family: $font-stack !important;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}
// Google font ballback

.wf-active body {
  opacity: 1;
}
// Title :: soft reset

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
// Title hierarchy :: Bigger to smaller

.h-like-a {}
@include media-breakpoint-down(lg) {
  .h-like-a {}
}
@include media-breakpoint-down(md) {
  .h-like-a {}
}
@include media-breakpoint-down(sm) {
  .h-like-a {}
}
@include media-breakpoint-down(xs) {
  .h-like-a {}
}
// Paragraph hierarchy :: Bigger to smaller

.p-like-a {}
@include media-breakpoint-down(lg) {
  .p-like-a {}
}
@include media-breakpoint-down(md) {
  .p-like-a {}
}
@include media-breakpoint-down(sm) {
  .p-like-a {}
}
@include media-breakpoint-down(xs) {
  .p-like-a {}
}
