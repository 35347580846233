// Footer

#footer-cta {
	background-image: url(../images/illustrations/bg-contact.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	padding: 120px 0;
	text-align: center;
	.title-a	{
		display: inline-block;
		padding: 5px 20px;
		font-size: px(16);
		margin: 0;
		font-weight: 400;
		background-color: $pk-a;
		color: $wh-a;
		border-radius: 30px;
	}
	.title-b {
		color: $pk-a;
		font-size: px(28);
		margin-top: px(40);
		margin-bottom: 0;
		line-height: 1.3em;
		.big {
			font-size: px(44);
			font-weight: 700;
			text-transform: uppercase;
			display: block;
			margin: 15px 0;
		}
		.tiny {
			font-size: px(18);
			span {
				font-size: px(24);
				font-weight: 700;
			}
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 75px 0;
		.title-a {
			font-size: px(14);
		}
		.title-b {
			margin-top: px(30);
			font-size: px(24);
			.big {
				font-size: px(34);
			}
			.tiny {
				font-size: px(14);
				span {
					font-size: px(22);
				}
			}
		}
	}
	@include media-breakpoint-down(xs) {
		padding: 50px 0;
		.title-a {
			font-size: px(13);
		}
		.title-b {
			margin-top: px(28);
			font-size: px(22);
			.big {
				font-size: px(32);
			}
			.tiny {
				line-height: 1em;
				font-size: px(13);
				span {
					font-size: px(20);
				}
			}
		}
	}
}

#footer {
	background-color: $gy-c;
	padding: px(8) 0 px(10);
	color: $wh-a;
	.logo {
		font-size: px(10);
	}
	.logo > * {
		display: inline-block;
		vertical-align: middle;
	}
	.logo span {
		padding-top: 13px;
		padding-right: 10px;
	}
	.list {
		.item {
			display: inline-block;
		}
		.item:first-child:after {
			content:"|";
			font-size: px(10);
		}
		.link {
			font-size: px(10);
			color: $wh-a;
		}
	}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(xs) {}
	padding-bottom: px(15);
}
