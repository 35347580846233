.table {
  td {
    vertical-align: middle;
    padding: 30px;
  }

  th {
    padding: 20px 30px;
    line-height: 1.2em;
  }

  img {
    width: 200px;
    max-width: 200px;
  }
  @include media-breakpoint-down(sm) {
    border: none;

    thead {
      display: none;
    }

    td {
      display: block !important;
    }

    tr {
      margin-bottom: 10px;
      display: block;
      border-bottom: 2px solid #ddd;
    }

    td {
      display: block;
      text-align: left;
      font-size: 13px;
      padding: 0;
      border-bottom: 1px dotted #ccc;
      padding: 35px 10px 10px;
      position: relative;
      border: none;
      background-color: tint($gy-a,50%);
    }

    tr td:first-child:before {
      display: none;
    }

    tr td:first-child {
     padding: 0;
     background-color: $wh-a;
    }

    td:last-child {
      border-bottom: 0;
    }

    td:before {
      content: attr(data-label);
      width: 100%;
      display: block;
      text-transform: uppercase;
      background-color: #212529;
      color: $wh-a;
      font-weight: bold;
      padding: 5px 10px;
      width: calc(100%);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
