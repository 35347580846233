// Header :: Fixed or small/large

body {
	padding-top: 0;
}

.anchor-scroll {
	display: block;
	margin-top: 0;
	position: absolute;
	width: 100%;
	height: 1px;
	background-color: transparent;
}
@include media-breakpoint-down(lg) {
	body {
		padding-top: 0;
	}

	.anchor-scroll {
		margin-top: 0;
	}
}
// Header :: Small on scroll

body.header-small #header {}
@include media-breakpoint-up(lg) {
	body.header-small #header {}
}
// Navigation :: Force Display on large device
@include media-breakpoint-up(sm) {
	#nav-main {
		display: flex !important;
	}

	#nav-mobile-trigger {
		display: none;
	}
}
// Header :: Structure

#header {
	position: relative;
	z-index: 10;
	width: 100%;
	padding: 0;
	box-shadow: 0 2px 4px rgba(0,0,0,0.05);
	transition: all 0.2s ease-in-out;
	@include media-breakpoint-down(xs) {
		#nav-mobile-trigger {
			display: block;
			right: 0;
			position: absolute;
			z-index: 10;
			background-color: $pk-a;
			top: 27px;
			width: 60px;
			height: 60px;
		}
	}
}
@include media-breakpoint-down(xs) {
	@-moz-document url-prefix() {
		#header #nav-mobile-trigger {
			top: 28px;
		}
	}
}
@media not all and (min-resolution:.001dpcm) {
	@supports (-webkit-appearance:none) and (stroke-color:transparent) {
		@include media-breakpoint-down(xs) {
			#header #nav-mobile-trigger {
				top: 28px;
			}
		}
	}
}
// Navigation :: Top
#nav-top {
	background-color: $gy-c;
	color: $gy-a;
	text-align: right;
	padding: 5px 0;

	.txt {
		text-transform: uppercase;
		font-size: px(11);
		margin-bottom: 0;
	}
	@include media-breakpoint-down(lg) {
		.txt {
			font-size: px(10);
		}
	}
	@include media-breakpoint-down(md) {
		text-align: center;

		.txt {
			font-size: px(10);
		}
	}
	@include media-breakpoint-down(sm) {
		.txt {
			font-size: px(9);
		}
	}
	@include media-breakpoint-down(xs) {
		.txt {
			font-size: px(12);
		}

		.txt > a:first-child:after {
			content: "|";
			padding-left: 5px;
			margin-right: 3px;
		}
	}
}
// Navigation :: Main

#nav-main-wp {
	min-height: px(85);
	position: relative;
	padding-left: px(300);

	#logo {
		position: absolute;
		top: px(-32);
		left: px(-15);
		z-index: 2;
	}

	#btn-phone {
		display: inline-block;
		position: relative;
		margin-top: px(12);
		padding-left: 55px;
		color: $pk-a;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@extend .sprite;
			@extend .icon-phone;
		}

		.link {
			font-weight: 700;
			display: block;
			font-size: px(19);
			line-height: 1.2em;
		}

		.txt {
			font-size: px(10);
			text-transform: uppercase;
			letter-spacing: 0.08em;
		}
	}
	@include media-breakpoint-down(md) {
		padding-left: 0;

		#logo {
			top: px(-25);
		}

		#btn-phone {
			margin-top: px(25);
		}
	}
	@include media-breakpoint-down(xs) {
		min-height: px(60);

		#logo {
			width: px(200);
			left: 0;
		}

		#btn-phone {
			display: none;
		}
	}
}

#nav-main {
	padding-left: px(50);
	display: flex;
	height: px(85);
	align-items: center;
	justify-content: center;

	.item {
		flex-grow: 1;
		text-align: center;
		height: px(85);
	}

	.link {
		display: inline-block;
		position: relative;
		color: $gy-c;
		padding-top: px(30);
		font-size: px(16);
		height: px(85);
		transition: all 0.2s ease-in-out;
	}

	.link:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 6px;
		background-color: $gy-a;
		transition: all 0.2s ease-in-out;
	}

	.link.active:after,
	.link:focus:after,
	.link:hover:after {
		background-color: $pk-a;
	}

	.link.active {
		color: $pk-a;
	}
	@include media-breakpoint-down(lg) {
		padding-left: 0;
	}
	@include media-breakpoint-down(md) {
		height: auto;
		margin-top: px(25);
		border-top: 1px solid $gy-a;
		padding-top: px(10);

		.item,
		.link {
			height: px(40);
		}

		.link {
			padding-top: 0;
		}
	}
	@include media-breakpoint-down(xs) {
		display: none;
		border: none;
		background-color: tint($gy-a,75%);
		margin-top: px(60);
		padding: 0;

		.item,
		.link {
			height: auto;
		}

		.item:not(:last-child) {
			border-bottom: 1px solid $gy-a;
		}

		.link:after {
			display: none;
		}

		.link {
			padding: px(7) px(15);
			display: block;
			width: 100%;
		}
	}
}
