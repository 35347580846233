// Custom variables :: Colors
//
// -> bk : black;
// -> gy : grey;
// -> wh : white;
// -> pu : purple;
// -> bl : blue;
// -> br : brown;
// -> or : orange;
// -> gn : green;
// -> pk : pink;
$bk-a: #000000;
$wh-a: #FFFFFF;
$gy-a: #dadada;
$gy-b: #a1a1a1;
$gy-c: #3f3f3f;
$pk-a: #c25da6;
$pk-b: #f5e5f1; // Color

.c-bk-a {
  color: $bk-a;
}

.c-wh-a {
  color: $wh-a;
}
.c-gy-a {
  color: $gy-a;
}

.c-gy-b {
  color: $gy-b;
}

.c-gy-c {
  color: $gy-c;
}

.c-pk-a {
  color: $pk-a;
}

.c-pk-b {
  color: $pk-b;
}

// Background
.bg-bk-a {
  background-color: $bk-a;
}

.bg-wh-a {
  background-color: $wh-a;
}

.bg-gy-a {
  background-color: $gy-a;
}

.bg-gy-b {
  background-color: $gy-b;
}

.bg-gy-c {
  background-color: $gy-c;
}
.bg-gy-d {
  background-color: tint($gy-a, 75%);
}

.bg-pk-a {
  background-color: $pk-a;
}

.bg-pk-b {
  background-color: $pk-b;
}
