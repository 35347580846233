// Sprite :: Structure

.sprite {
	display: inline-block;
	background-image: url("../images/icons/sprite.png");
	background-repeat: no-repeat;
	vertical-align: text-top;
	background-size: 1100px;
}

.sprite.icon-check {
	width: 30px;
	height: 30px;
	background-position: -50px 0;
}

.sprite.icon-phone {
	width: 50px;
	height: 50px;
	background-position: -80px 0;
}

.sprite.img-1,
.sprite.img-2,
.sprite.img-3 {
	width: 100px;
	height: 100px;
	background-position: -80px 0;
}

.sprite.img-1 {
	background-position: -140px 0;
}

.sprite.img-2 {
	background-position: -240px 0;
}

.sprite.img-3 {
	background-position: -340px 0;
}

.sprite.back-top {
	width: 50px;
	height: 50px;
}

.sprite.back-top {
	background-position: 0 0;
}
