// Body

body {
	overflow: hidden;
	overflow-y: auto;
}

body,
html {
	width: 100%;
	background-color: #fff;
}

html {
	overflow-x: hidden;
}
// Utils :: Image renderer optimize

.renderer-optmimize {
	image-rendering: -moz-crisp-edges;
	image-rendering: -moz-crisp-edges;
	image-rendering: -o-crisp-edges;
	image-rendering: -webkit-optimize-contrast;
	-ms-interpolation-mode: nearest-neighbor;
}
// Utils :: Hide play button on video iphone

*::-webkit-media-controls-start-playback-button {
	display: none !important;
	-webkit-appearance: none;
}
// Components :: Magnific popup

.mfp-close {
	width: 30px !important;
	height: 30px;
	line-height: 30px;
	position: absolute;
	right: 0;
	top: 5px;
	text-decoration: none;
	text-align: center;
	opacity: 0.65;
	filter: alpha(opacity=65);
	padding: 0 0 18px 10px;
	color: #FFF;
	font-style: normal;
	font-size: 28px;
	font-family: Arial, Baskerville, monospace;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	background-position: -60px 0 !important;
	text-indent: 999em;
	overflow: hidden!important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow {
	position: absolute;
	opacity: 0.65;
	filter: alpha(opacity=65);
	margin: 0;
	top: 50%;
	margin-top: -55px;
	padding: 0;
	width: 30px;
	height: 60px;
	display: block;
	background-image: url("../images/icons/sprite-popup.png") !important;
	transition: all 0.2s ease-in-out;
	background-size: 150px !important;
}

.mfp-arrow-left {
	left: 20px;
	background-position: 0 0 !important;
}

.mfp-arrow-right {
	right: 20px;
	background-position: -30px 0 !important;
}

.mfp-arrow:after,
.mfp-arrow:before {
	display: none !important;
}

.mfp-zoom-out .mfp-with-anim {
	opacity: 0;
	transform: scale(1.3);
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-bg {
	opacity: 0;
	transition: all 0.2s ease-in-out;
}

.mfp-zoom-out.mfp-ready .mfp-with-anim {
	opacity: 1;
	transform: scale(1);
}

.mfp-zoom-out.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-zoom-out.mfp-removing .mfp-with-anim {
	transform: scale(1.3);
	opacity: 0;
}

.mfp-zoom-out.mfp-removing.mfp-bg {
	opacity: 0;
}
// Components :: Slick

.slick-slide {
	outline: none !important;
}

.slick-vertical .slick-slide {
	border: none !important;
}

.slick-dots {
	margin-top: px(15);
	text-align: center;

	li {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}

	button {
		width: 14px;
		height: 14px;
		margin-right: 5px;
		background-color: $bk-a;
		border-radius: px(18);
		transition: all 0.2s ease-in-out;
		overflow: hidden;
		text-indent: -999em;
	}

	.slick-active button {
		background-color: $bk-a;
	}

	button:focus,
	button:hover {
		background-color: $bk-a;
	}
}
// Components :: WOW

.fadeIn,
.fadeInDown,
.fadeInLeft,
.fadeInRight,
.fadeInUp {
	visibility: hidden;
}
// Function :: Equal Col
@include media-breakpoint-down(md) {
	.equal-col-item {
		min-height: 0 !important;
	}
}
// Component :: Google map

.map-wrapper {
	position: relative;
	height: 400px;
	background-color: #e5e3df;

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
// Component :: Parallax

.parallax-mirror {
	z-index: 0 !important;
}

.parallax-wp {
	@extend .clearfix;
	overflow: hidden;
	position: relative;
	height: 300px;
	background: transparent;
}
@include media-breakpoint-down(sm) {}
@include media-breakpoint-down(xs) {}
// Slick :: Home

#slick-wp {}

#slick {}

#slick-wp {
	.item {}

	#slick-arrows {
		.arrow-next,
		.arrow-prev {}

		.arrow-prev {}

		.arrow-next {}
	}

	.slick-dots {}
	@include media-breakpoint-down(lg) {}
	@include media-breakpoint-down(md) {}
	@include media-breakpoint-down(sm) {}
	// Animation classes
	// .slick-slide .content							{ opacity: 0; transform: translateY(-30px) scale(1); transition: all 0.2s ease-in-out 0.5s;}
	// .slick-slide.animation .content					{ opacity: 1; transform: translateY(0) scale(1); }
}
// Home top

#home-top {
	position: relative;
	background-image: url("../images/illustrations/bg-home.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	height: 370px;

	.container {
		height: 100%;
		position: relative;
	}

	.car-img {
		position: absolute;
		bottom: -170px;
		left: -100px;
		z-index: 3;
	}

	.title-wp {
		z-index: 2;
		width: 65%;
		position: absolute;
		top: 115px;
		left: 30%;
		background-color: rgba(0,0,0,0.3);
		color: $wh-a;
		padding: px(25) px(40);
		border-left: 4px solid $pk-a;

		.title-a {
			font-family: $font-fantaisy;
			font-size: px(34);
			font-weight: 400;
			line-height: 1em;
		}

		.title-b {
			font-size: px(18);
			font-weight: 400;
			line-height: 1.4em;
		}
	}
	@include media-breakpoint-down(md) {
		height: 300px;

		.car-img {
			width: 500px;
			bottom: -135px;
		}

		.title-wp {
			left: 35%;
			width: 65%;
			top: 70px;

			.title-a {
				font-size: px(30);
			}

			.title-b {
				font-size: px(16);
			}
		}
	}
	@include media-breakpoint-down(sm) {
		height: 250px;

		.car-img {
			width: 350px;
			bottom: -100px;
		}

		.title-wp {
			width: 80%;
			left: 18%;
			top: 60px;

			.title-b {
				font-size: px(13);
			}
		}
	}
	@include media-breakpoint-down(xs) {
		height: auto;
		padding: 30px 15px;

		.car-img {
			display: none;
		}

		.title-wp {
			padding: 20px;
			position: relative;
			width: 100%;
			left: auto;
			top: auto;

			.title-b {
				font-size: px(13);
			}
		}
	}
}

.txt-home-intro {
	line-height: 1.8em;
	text-align: justify;
}

.item-intro {
	z-index: 11;
	position: relative;
	padding-top: 30px;
	padding-left: 120px;

	&.except {
		padding-top: 15px;
	}

	.sprite {
		position: absolute;
		top: 0;
		left: 0;
	}

	.title {
		margin-bottom: 5px;
		line-height: 1.1em;
		font-weight: 700;
	}

	.txt {
		font-size: px(12);
		margin-bottom: 0;
	}
	@include media-breakpoint-down(lg) {
		padding-top: 25px;

		&.except {
			padding-top: 13px;
		}
	}
	@include media-breakpoint-down(md) {
		text-align: center;
		padding-top: 0 !important;
		padding-left: 0;

		.sprite {
			display: block;
			position: relative;
			top: auto;
			left: auto;
			margin: 0 auto 15px;
		}
	}
	@include media-breakpoint-down(sm) {
		min-height: 110px;
		text-align: left;
		padding-top: 25px !important;
		padding-left: 120px;

		.sprite {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			margin: 0;
		}
	}
	@include media-breakpoint-down(xs) {
		text-align: center;
		min-height: auto;
		padding-top: 0 !important;
		padding-left: 0;

		.sprite {
			display: block;
			position: relative;
			top: auto;
			left: auto;
			margin: 0 auto 15px;
		}
	}
}

.list-atouts {
	text-align: center;

	.item {
		text-align: left;
		display: block;
		margin-top: px(20);
		position: relative;
		padding-left: 50px;
		font-size: px(16);
		font-weight: 700;

		strong {
			color: $pk-a;
		}

		&:after {
			content: "";
			@extend .sprite;
			@extend .icon-check;
			position: absolute;
			top: 50%;
			margin-top: -13px;
			left: 0;
		}
	}
	@include media-breakpoint-down(xs) {
		.item {
			font-size: px(14);
		}
	}
}

#home-cat-list {
	position: relative;
	padding: 130px 0 50px;
	background-image: url("../images/illustrations/bg-car.jpg");
	background-size: cover;
	background-repeat: no-repeat;

	&:before {
		content: "";
		position: absolute;
		z-index: 1;
		left: -5%;
		width: 110%;
		height: 175px;
		border-radius: 100%;
		background-color: $wh-a;
		top: -100px;
	}

	.title-bottom {
		font-size: px(17);
		font-weight: 700;
		text-align: center;
		line-height: 1.6em;
	}

	.link {
		margin-top: 46px;
		position: relative;
		display: block;
		text-align: center;
		background-color: $wh-a;
		border-radius: 10px;
		padding: 70px 20px 30px;
		transition: all 0.2s ease-in-out;

		&:hover {
			transform: translateY(-10px);
		}

		.title {
			font-size: px(18);
			font-weight: 700;
			line-height: 1.3em;
			color: $pk-a;
		}

		.list {
			margin-top: px(14);
			font-size: px(16);

			.item {
				margin-top: 10px;
			}
		}

		.img-fluid {
			position: absolute;
			top: -46px;
			left: 50%;
			margin-left: -46px;
		}
	}
	@include media-breakpoint-down(md) {
		margin-top: -50px;
	}
	@include media-breakpoint-down(xs) {
		.title-bottom {
			font-size: px(16);
		}
		padding-top: 110px;
		padding-bottom: 30px;
	}
}

.except-index {
	position: relative;
	z-index: 11;
}
// Header Page// Page header

#header-page {
	position: relative;
	background-image: url("../images/illustrations/bg-home.jpg");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	color: $wh-a;
	text-align: center;
	padding: 55px 0;

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.4);
		z-index: 1;
	}

	.title,
	.title > h1 {
		position: relative;
		z-index: 2;
		letter-spacing: 0.05em;
	}

	.title {
		font-size: px(34);
	}

	.title:after {
		content: "";
		display: block;
		width: 30px;
		height: 3px;
		background-color: $wh-a;
		margin: 5px auto 0;
	}
	@include media-breakpoint-down(md) {
		padding: 45px 0;

		.title {
			font-size: px(26);
		}
	}
	@include media-breakpoint-down(sm) {
		padding: 30px 0;

		.title {
			font-size: px(20);
		}
	}
}
// Contact

.map-wrapper {
	position: relative;

	.map-canvas {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	&.size-b .map-canvas {
		height: 400px;
	}

	.figcaption {
		position: absolute;
		top: 0;
		width: 400px;
		right: -250px;
		height: 100%;
		display: flex;
		justify-content: center;
		flex-flow: column nowrap;
	}

	&.except .figcaption {
		right: auto;
		left: -250px;
	}

	.map-canvas.except .figcaption {
		display: none;
	}

	.figcaption .list {
		background-color: $wh-a;
		display: inline-block;
		padding: 30px;
		box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.1);
	}

	.figcaption .item {
		position: relative;
		padding-left: 35px;
		font-weight: 600;
		line-height: 1.5em;
	}

	.figcaption .item:not(:last-child) {
		margin-bottom: 10px;
	}

	.figcaption .item .icon {
		font-size: px(20);
		position: absolute;
		color: $pk-a;
		top: 50%;
		margin-top: -9px;
		left: 0;
	}
	@include media-breakpoint-down(sm) {
		&.size-b .map-canvas {
			height: 200px;
		}

		.figcaption {
			position: relative;
			top: auto;
			width: 100%;
			padding: 0;
			left: auto !important;
			right: auto;
			top: auto;
			height: auto;
		}

		.figcaption .item {
			font-size: px(13);
		}
	}
}
